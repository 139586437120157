// 업무상태에 대한 필터 함수를 정의한다.
export default (value) => {
  if (!value) return ''
  const statusArray = [
    { text: '', value: 0 },
    { text: '예정', value: 1 },
    { text: '완료', value: 2 },
    { text: '취소', value: 3 }
  ]
  return statusArray.find(s => s.value === value).text
}
